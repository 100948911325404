<template>
  <div class="pt-4">
    <router-link
      v-if="$route.params.name !== undefined"
      to="/voicemails"
      class="back-link px-3 px-md-0"
    >
      <v-icon size="24px">
        navigate_before
      </v-icon>
      Voicemails
    </router-link>
    <h1 :class="$vuetify.breakpoint.smAndDown ? 'font-weight-semi-bold fz-25' : 'font-weight-semi-bold fz-28'" class="dark-text px-4 px-md-0">
      {{ $route.params.name !== undefined ? upperCaseFirst($route.params.name) + ' mailbox' : 'Voicemails' }}
      <!-- <div class="pointer">
        <v-icon color="#A7A5B1">
          filter_list
        </v-icon>
        <span class="fz-14 velvet-grey font-weight-regular">
          Filter unread
        </span>
      </div> -->
    </h1>
    <div class="light-text fz-14 font-weight-regular d-flex mb-4 px-3 px-md-0">
      <v-btn
        text
        to="/create-mailbox"
        class="text-none d-flex align-center px-2 mr-2"
        height="36px"
        v-if="$store.state.auth.currentUser.role === 'company_admin'"
      >
        <v-icon size="24px" class="mr-1" color="#919EAB">add</v-icon>
        <span class="light-text">Create Mailbox</span>
      </v-btn>

      <v-btn
        text
        class="text-none d-flex align-center px-2"
        height="36px"
        @click="filterUnreadStatus = !filterUnreadStatus"
      >
        <v-icon
          size="24px"
          class="mr-1"
          :color="filterUnreadStatus ? '#5C6AC4' : '#919EAB'"
        >
          filter_list
        </v-icon>
        <span
          :class="filterUnreadStatus ? 'indigo--text' : 'light-text'"
        >
          Filter unread</span>
      </v-btn>
    </div>
    <v-card class="fz-14 mb-5 mx-4 ml-lg-0 mr-lg-4" :loading="loading">
      <v-list class="pa-0">
        <template v-for="(vm, vmIndex) in voicemails">
          <v-divider
            v-if="vmIndex"
            :key="vmIndex + 'divider'"
          ></v-divider>
          <track-item
            :key="vmIndex + 'track'"
            :index="vmIndex"
            :value="vm"
            :isView="'Voicemails'"
            class="px-5 py-1"
            :style="vm.is_read ? '' : 'background-color:#F3F4FA'"
            :sendMessageDialog.sync="sendMessageDialog"
            :editDialog.sync="editDialog"
            ref="track"
            @sendEmailDialog="openSendViaEmail($event, vm)"
            @onPauseChange="onPauseChange($event, vm, vmIndex)"
            @removeTrack="setRemoveTrackDialog"
          />
        </template>
        <v-divider/>
        <div
          v-if="countPage > 1"
          class="d-flex justify-space-between"
        >
          <div class="ma-5 br-3 grey-button d-flex">
            <template v-for="index in countPage">
              <v-divider :key="index + 'v-divider--page'" v-if="index" vertical class="pa-0"/>
              <v-btn
                :class="{'grey-button--selected': index === page}"
                text
                tile
                min-width="56"
                class=""
                :key="index"
                @click="pagination(index)"
              >{{ index }}
              </v-btn>
            </template>
          </div>

          <div class="ma-5 br-3 grey-button d-flex">
            <v-btn
              text
              tile
              class="px-0"
              min-width="36"
              width="36px"
              @click="previousPage(page)"
            >
              <v-icon small>
                navigate_before
              </v-icon>
            </v-btn>
            <v-divider vertical/>
            <v-btn
              text
              tile
              min-width="36"
              class="light-text px-0"
              width="36px"
              @click="nextPage(page)"
            >
              <v-icon small>
                navigate_next
              </v-icon>
            </v-btn>
          </div>
        </div>
        <div
          v-if="!voicemails.length && !loading"
          class="py-13 px-3 text-center grey--text text--darken-1 fz-14"
        >
          No unread messages
        </div>
      </v-list>
    </v-card>
    <vue-snotify />
    <SendViaEmail
      :dialog="sendEmailDialog"
      :sendEmailDialog.sync="sendEmailDialog"
      :data="emailDialogData"
      @onSend="sendViaEmail"
    />
    <SendAsMessage :dialog="sendMessageDialog" :sendMessageDialog.sync="sendMessageDialog"/>
    <TranscriptionModal :dialog="editDialog" :editDialog.sync="editDialog"/>
    <v-dialog
      v-model="removeTrackDialog"
      persistent
      max-width="400px"
    >
      <v-card class="pa-0">
        <v-card-title class="font-weight-bold">
          Delete
        </v-card-title>

        <v-card-subtitle>
          Are you sure you want to delete voicemail?
        </v-card-subtitle>

        <v-card-actions class="pb-5 pt-0 justify-md-end">
          <v-btn
            color="secondary"
            outlined
            class="mx-2 px-4 text-capitalize"
            @click="closeRemoveTrackDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            depressed
            color="primary"
            class="mx-2 px-4 text-capitalize"
            @click="removeTrack"
          >
            Delete
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TrackItem from '../components/TrackItem'
import SendAsMessage from '@/components/SendAsMessage'
import SendViaEmail from '@/components/SendViaEmail'
import TranscriptionModal from '@/components/TranscriptionModal'
import { mapActions } from 'vuex'

export default {
  name: 'VoicemailsOverview',
  components: { TranscriptionModal, SendViaEmail, SendAsMessage, TrackItem },
  data: () => ({
    playedIndex: null,
    filterUnreadStatus: false,
    sendEmailDialog: false,
    emailDialogData: {},
    sendMessageDialog: false,
    editDialog: null,
    voicemails: [],
    mailboxes: [],
    total: 0,
    page: 1,
    take: 10,
    removeTrackDialog: false,
    removeTrackUuid: '',
    loading: false
  }),
  computed: {
    countPage () {
      return Math.ceil(this.total / this.take)
    }
  },
  watch: {
    page () {
      this.loadRecordings()
    },
    filterUnreadStatus () {
      this.loadRecordings()
    }
  },
  async created () {
    if (!this.$store.state.auth.currentUser.role === 'account_admin') return
    await this.loadMailboxes()
    this.loadRecordings()
  },
  methods: {
    ...mapActions([
      'deleteVoicemail', 'getVoicemails', 'getMailboxes',
      'sendViaEmailVoicemail', 'toggleVoicemailReadStatus'
    ]),
    async loadRecordings () {
      try {
        this.loading = true

        const filter = {
          orderType: 'DESC',
          orderBy: 'created_at',
          skip: (this.page - 1) * this.take,
          take: this.take
        }

        if (this.filterUnreadStatus) filter.is_read = false

        const { data } = await this.getVoicemails(filter)

        const voicemails = []
        for (const voicemail of data.data) {
          voicemails.push({
            ...voicemail,
            mailbox_name: this.getMailbox(voicemail.mailbox_uuid)
          })
        }

        this.voicemails = voicemails
        this.total = data.total
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    async loadMailboxes () {
      try {
        this.loading = true
        const { data } = await this.getMailboxes({ take: 499 })
        if (!data) return
        this.mailboxes = data.mailboxList
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    getMailbox (uuid) {
      if (!uuid) return ''
      try {
        const data = this.mailboxes.find(e => e.uuid === uuid)
        return data ? data.name : ''
      } catch (err) {
        console.log(err)
        return ''
      }
    },
    onPauseChange (event, item, index) {
      if (index !== this.playedIndex && this.playedIndex !== null) {
        this.$refs.track[this.playedIndex].togglePlaying()
      }
      this.playedIndex = event ? null : index
      if (item.is_read) return
      this.setVoicemailRead(item.uuid, index)
    },
    async setVoicemailRead (uuid, index) {
      try {
        const { data } = await this.toggleVoicemailReadStatus({ uuid, trigger: true })
        if (!data) return
        this.voicemails[index].is_read = true
      } catch (err) {
        throw new Error(err)
      }
    },
    pagination (index) {
      this.page = index
    },
    previousPage (page) {
      if (page > 1) {
        this.page = page - 1
      }
    },
    nextPage (page) {
      if (page < this.countPage) {
        this.page = page + 1
      }
    },
    upperCaseFirst (str) {
      if (!str) return str
      return str[0].toUpperCase() + str.slice(1)
    },
    setRemoveTrackDialog (uuid) {
      this.removeTrackUuid = uuid
      this.removeTrackDialog = true
    },
    closeRemoveTrackDialog () {
      this.removeTrackUuid = ''
      this.removeTrackDialog = false
    },
    async removeTrack () {
      if (!this.removeTrackUuid) return
      try {
        const { data } = await this.deleteVoicemail(this.removeTrackUuid)
        if (!data.success) return

        this.$snotify.notifications = []
        this.$snotify.success('Removed successfuly')

        await this.loadRecordings()
      } catch (err) {
        this.$snotify.error(err)
        throw new Error(err)
      } finally {
        this.closeRemoveTrackDialog()
      }
    },
    openSendViaEmail (modal, data) {
      this.sendEmailDialog = modal
      this.emailDialogData = data
    },
    async sendViaEmail (data) {
      try {
        await this.sendViaEmailVoicemail(data)

        this.sendEmailDialog = false
        this.emailDialogData = {}

        this.$snotify.notifications = []
        this.$snotify.success('Send successfuly')
      } catch (err) {
        this.$snotify.error(err.response.data.message)
      }
    }
  },
  mounted () {
    if (this.$route.params.name !== undefined) {
      this.$store.dispatch('getVoicemailMailbox', {
        name: this.$route.params.name
      })
    }
  }
}
</script>
